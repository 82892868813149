
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    menu_icon: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    showMenu() {},
  },
};
